import {createSelector} from "reselect";
import {Selectors as CompanySelectors} from 'modules/companies';

export const sliceSelector = state => state.notifs;
export const fetchingSelector = createSelector(sliceSelector, slice => !!slice.fetching);
export const loadingSelector = createSelector(sliceSelector, slice => !!slice.loading);
export const justCreatedSelector = createSelector(sliceSelector, slice => !!slice.justCreated);
export const permissionCanBeGranted = createSelector(sliceSelector, slice => slice.notifStat === 'default');
export const permissionGranted = createSelector(sliceSelector, slice => slice.notifStat === 'granted');
export const showPermissionModal = createSelector(sliceSelector, slice => !!slice.showPermissionModal);
export const notifsHistorySelector = createSelector(sliceSelector, slice => slice.history || []);
export const hasMoreNotifsHistorySelector = createSelector(sliceSelector, slice => !!slice.hasMoreHistory);
export const lastNotifHistorySelector = createSelector(sliceSelector, slice => slice.lastHistory);
export const sortingSelector = createSelector(sliceSelector, slice => slice.sorting || {id: 'createdAt', desc: true});

export const settingsSelector = createSelector(sliceSelector, CompanySelectors.matchingCompanySelector,
    (slice, cmp) => {
        const settings = slice.settings || {};
        if (cmp) {
            return {...settings,
                whatsapp_enabled: cmp.allowLandbotNotifications,
                new_emp_wa_enabled: cmp.allowNewEmployeeWaNotifications,
                amountLeft: cmp.notifLeft,
                enabled: cmp.notifEnabled,
                amountWaLeft: cmp.whatsappLeft
            }
        }
        return settings;
    }
);
