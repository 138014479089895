export default {
    'general_add': 'הוספה',
    'general_cancel': 'ביטול',
    'general_back': 'חזרה',
    'general_delete': 'מחיקה',
    'general_close': 'סגירה',
    'general_save': 'שמירה',
    'general_': ' ',
    'general_true': 'כן',
    'general_false': 'לא',
    'general_submit': 'שליחה',
    'general_filter': 'סינון',
    'general_yes': 'כן',
    'general_no': 'לא',
    'general_approve': 'אישור',
    'general_nextTime': 'אולי בפעם אחרת',
    general_reset: 'איפוס',
    general_redacted: '<הוסר>',

    'clickToCopy_copy': 'העתק',
    'clickToCopy_copied': 'הועתק',
    'clickToCopy_not_copied': 'שגיאה, לא הועתק',

    'loading': 'טוען...',
    'login_header': 'טופס התחברות',
    'login_userLabel': 'שם משתמש',
    'login_passwordLabel': 'סיסמה',
    'login_mismatch': 'שם משתמש וסיסמה אינם תואמים',
    'login_blockedUser': 'משתמש נחסם. אנא פנו לתמיכה ',
    'login_failed': 'אופס, קרתה שגיאה',
    reset_failed: 'אופס, קרתה שגיאה',
    'pass_expired': 'פג תוקפה של הסיסמא. נא לאפס סיסמא',
    'login': 'התחבר',
    'login_joinUs': 'עדיין אין לכם לינקאצ\'?',
    'login_joinUsLink': 'ליצירת קשר ופרטים נוספים',
    
    login_resetPass_header: 'איפוס סיסמה',
    login_forgotPassword: 'שכחת את הסיסמה?',
    login_emailLabel: 'אימייל',
    login_newPassLabel: 'סיסמה חדשה',
    resetPassword_submit: 'איפוס סיסמה',

    resetPassword__confirmed_title: 'הסיסמה שונתה בהצלחה',
    resetPassword__sent_title: 'אם מצאנו חשבון המשוייך למשתמש זה, שלחנו אימייל לכתובת המייל המשוייכת לחשבון',
    resetPassword_invalidToken: 'טוקן לא חוקי או פג תוקף',
    resetPassword_weakPassword: 'סיסמה חלשה מדי, יש לנסות סיסמה חזקה יותר',
    resetPassword_samePassword: 'סיסמה זהה, יש לבחור סיסמא חדשה',

    password_minLength: 'מינימום {count} תוים',
    password_oneUpperCase: 'לפחות אות גדולה אחת',
    password_oneLowerCase: 'לפחות אות קטנה אחת',
    password_oneNumber: 'לפחות מספר אחד',
    password_oneSpecialChar: 'לפחות תו מיוחד אחד',

    'access_unauthorizedToView': 'אין לך גישה לדף זה',

    'company_alias': 'מזהה חברה (אנגלית)',
    'company_locale': 'שפת ממשק חברה',
    'company_name': 'שם החברה',
    'company_logo': 'לוגו',
    'company_cover': 'באנר',
    'company_coverMobile': 'באנר למובייל',
    'company_website': 'אתר החברה',
    'company_about': 'קצת עלינו',
    'company_terms': 'הטבות החודש',
    'company_location': 'מטה החברה',
    'company_contactName': 'שם איש קשר (לשימוש פנימי)',
    'company_contactPhone': 'טלפון איש קשר (לשימוש פנימי)',
    'company_contactEmail': 'אימייל איש קשר (לשימוש פנימי)',
    'company_leadEmails': 'אימיילים למשלוח לידים',
    'company_rIncentive': 'משפט תמריץ לעובד ממליץ',
    'company_aIncentive': 'משפט תמריץ למועמד',
    'company_referrerWorthTitle': 'כותרת',
    'company_referrerWorth': 'למה זה כדאי לי',
    'company_numEmployees': 'מספר עובדים',
    'company_cover_description': 'גרור לכאן באנר, או לחץ כדי לבחור (jpg, png, mp4)',
    'company_coverMobile_description': 'גרור לכאן באנר למכשירי מובייל, או לחץ כדי לבחור (jpg, png, mp4)',
    'company_logo_description': 'גרור לכאן את לוגו החברה, או לחץ כדי לבחור (jpg, png)',
    'company_newApplicantEmailTitle': 'כותרת מייל למועמד חדש',
    company_applicantSavePolicy: 'מדיניות שמירת מועמדים',
    company_applicantSavePolicy_default: 'ברירת מחדל',
    company_applicantSavePolicy_skipCV: 'דילוג על שמירת קו"ח',
    company_applicantSavePolicy_redactInfo: 'הסרת כל הפרטים המזהים',
    'company_termsPageCover': 'באנר תנאים והטבות',
    'company_termsPageCoverMobile': 'באנר תנאים והטבות למובייל',
    'company_termsPageCover_description': 'גרור לכאן באנר, או לחץ כדי לבחור (jpg, png, mp4)',
    'company_termsPageCoverMobile_description': 'גרור לכאן באנר למכשירי מובייל, או לחץ כדי לבחור (jpg, png, mp4)',

    'company_referrerMeta': 'תצוגה מקדימה בשיתוף הלינק - עובד ממליץ',
    'company_applicantMeta': 'תצוגה מקדימה בשיתוף הלינק - מועמד',
    'company_referrerMetaTitle': 'כותרת',
    'company_referrerMetaDescription': 'תאור',
    'company_referrerMetaImg': 'תמונה',
    'company_referrerMetaImgFB': 'תמונה לפייסבוק/לינקדאין',
    'company_referrerMetaImg_description': 'גרור לכאן תמונה (jpg, png)',
    'company_referrerMetaImgFB_description': 'גרור לכאן תמונה (jpg, png)',
    'company_applicantMetaTitle': 'כותרת',
    'company_applicantMetaDescription': 'תאור',
    'company_applicantMetaImg': 'תמונה',
    'company_applicantMetaImgFB': 'תמונה לפייסבוק/לינקדאין',
    'company_applicantMetaImg_description': 'גרור לכאן תמונה (jpg, png)',
    'company_applicantMetaImgFB_description': 'גרור לכאן תמונה (jpg, png)',
    company_referrerStatsImg_description: 'גרור לכאן תמונה (jpg, png)',
    company_referrerStatsDescription: 'תיאור',
    company_referrerStatsImg: 'תמונה לתיבת ההישגים',
    'company_generalPositionTitle': 'קורות-חיים להגשה מהירה',
    'company_allowGeneralPosition': 'האם לאפשר הגשת קו"ח מהירה?',
    'company_generalPositionInternalId': 'מס\' משרה כללית',
    'company_generalPositionEmail': 'כתובת מייל למשלוח קורות-חיים',
    'general_position': 'משרה כללית',

    'company_preview_company': 'צפה במשרות',
    'company_site_title': 'האתר שלנו',

    'gen_pos_applicant_send_cv_title': "שלח/י לנו קו\"ח",
    'gen_pos_applicant_send_cv_subtitle': "ונחפש עבורך את התפקיד הבא",
    'gen_pos_referrer_send_cv_title': "רוצה להגיש קו\"ח עבור חבר/ה?",
    'gen_pos_referrer_send_cv_subtitle': "אנחנו נחפש עבורו/ה את התפקיד הבא",
    'gen_pos_description1': 'מצאת משרה מתאימה? מומלץ להגיש קו"ח ישירות דרכה.',
    'gen_pos_description2': 'לא בטוח/ה איזו משרה הכי מתאימה? אפשר להגיש כאן.',
    'gen_pos_description3': 'חשוב למלא את פרטי <b class="application-form__stress">המועמד/ת</b>. אנחנו כבר נדע שזה הגיע דרכך.',

    company_jobs_bot_title: 'בוט לדף משרות',
    company_jobs_tracking_title: 'אנליטיקס לדף משרות',
    company_jobs_tracking_bot_title: 'אנליטיקס ובוטים',
    company_tracking_ga_id: 'מזהה אנליטיקס Google Analytics',
    company_tracking_fbq_id: 'מזהה אנליטיקס Facebook Pixel',

    company_nobot_title: 'לחברה זו אין עדיין בוט לדף משרות. לחצו כאן כדי לבקש בוט לחברה זו',
    job_nobot_title: 'למשרה זו אין עדיין בוט. לחצו כאן כדי לבקש בוט למשרה זו',
    bot_request_button: '+ הוספת בוט',

    bot_requestbot_confirmation_title: 'האם אתם בטוחים שאתם רוצים לבקש בוט?',
    bot_requestbot_confirmation_content: 'אנו ניצור איתכם קשר לגבי בקשתכם.',

    company_bots_title: 'כותרת',
    company_bots_description: 'תיאור',
    company_bots_actionTypes: 'גורם הפעלה',
    company_bots_html: 'HTML Script',
    company_bots_designUrl: 'Desgin URL',
    company_bots_dataUrl: 'Data URL',
    company_bots_status: 'סטטוס',

    bot_actionType_candidate_pageload : 'טעינת דף מועמד',
    bot_actionType_employee_pageload : 'טעינת דף עובד',
    bot_actionType_cv_submitted : 'קורות חיים נשלחו',

    bot_status_draft: 'טיוטה',
    bot_status_internalReview: 'בחינת פנימית',
    bot_status_suspended: 'מושהה',
    bot_status_live: 'מופעל',
    bot_status_terminated: 'בוטל',
    bot_status_hidden: 'מוסתר',

    'job_links_title': 'שתפ/י משרה',

    'create_job_submit': 'צור משרה',
    'edit_job_submit': 'שמור משרה',
    create_job_submit_and_notify: 'צור ושלח התראה',

    'job_id': 'מזהה משרה',
    'job_shareUrlLink': 'לינק להפצה לכל העובדים',
    'job_title': 'כותרת משרה',
    'job_description': 'תיאור המשרה',
    'job_internalId': 'מספר משרה (לזיהוי פנימי, לא חובה)',
    'job_image': 'תמונה',
    'job_requirements': 'דרישות',
    'job_type': 'סוג משרה',
    'job_schedule': 'היקף משרה',
    'job_location': 'כתובת',
    job_locationTitle: 'תיאור מיקום',
    'job_posted': 'נוצרה',
    'job_salary': 'משכורת',
    'job_leadEmails': 'אימיילים למשלוח לידים',    
    'job_contactName': 'שם איש קשר (לשימוש פנימי)',
    'job_contactPhone': 'טלפון איש קשר (לשימוש פנימי)',
    'job_contactEmail': 'אימייל איש קשר (לשימוש פנימי)',
    'job_active': 'המשרה פעילה',
    'job_inactive': 'המשרה איננה פעילה',
    'job_rIncentive': 'משפט תמריץ לעובד ממליץ',
    'job_aIncentive': 'משפט תמריץ למועמד',
    'job_referrerWorthTitle': 'כותרת',
    'job_referrerWorth': 'למה זה כדאי לי',
    'job_cover': 'באנר משרה',
    'job_coverMobile': 'באנר משרה למובייל',
    'job_order': 'עדיפות',
    'job_innerRecruiter': 'מגייס/ת',
    'job_innerRecruiterManager': 'מנהל גיוס',
    'job_section': 'מחלקה/תחום',
    'job_aiInternalDescription': 'תיאור פנימי AI',

    'job_innerFields_title': 'שדות לשימוש פנימי',

    'job_recruiter_title': 'פרטי המגייס',
    'job_recruiterImg': 'תמונת המגייס',
    'job_recruiterName': 'שם המגייס',
    'job_recruiterHeadline': 'כותרת ב3 מילים',
    'job_recruiterDescription': 'תיאור קצר',

    'job_referrerMeta': 'תצוגה מקדימה בשיתוף הלינק - עובד ממליץ',
    'job_applicantMeta': 'תצוגה מקדימה בשיתוף הלינק - מועמד',
    'job_referrerMetaTitle': 'כותרת',
    'job_referrerMetaDescription': 'תאור',
    'job_referrerMetaImgFB': 'תמונה לפייסבוק/לינקדאין',
    'job_referrerMetaImg': 'תמונה',
    'job_referrerMetaImg_description': 'גרור לכן תמונה (jpg, png)',
    'job_referrerMetaImgFB_description': 'גרור לכן תמונה (jpg, png)',
    'job_applicantMetaTitle': 'כותרת',
    'job_applicantMetaDescription': 'תאור',
    'job_applicantMetaImg': 'תמונה',
    'job_applicantMetaImgFB': 'תמונה לפייסבוק/לינקדאין',
    'job_applicantMetaImg_description': 'גרור לכן תמונה (jpg, png)',
    'job_applicantMetaImgFB_description': 'גרור לכן תמונה (jpg, png)',

    'job_cover_description': 'גרור לכאן באנר שיחליף את באנר החברה עבור משרה זו (jpg, png, mp4)',
    'job_coverMobile_description': 'גרור לכאן באנר שיחליף את באנר החברה במכשירי מובייל עבור משרה זו (jpg, png, mp4)',
    'job_notSpecified': 'לא צוין',
    'job_preview_job': 'תצוגה מקדימה',

    'job_schedule_full': 'משרה מלאה',
    'job_schedule_part': 'משרה חלקית',
    'job_schedule_shifts': 'משמרות',
    'job_schedule_freelance': 'פרילנס',
    'job_schedule_relocation': 'רילוקיישן',

    job_bot_title: 'בוט למשרה',
    job_tracking_title: 'אנליטיקס לדף משרה',
    job_tracking_bot_title: 'אנליטיקס ובוטים לדף משרה',
    job_tracking_ga_id: 'מזהה אנליטיקס Google Analytics 4',
    job_tracking_fbq_id: 'מזהה אנליטיקס Facebook Pixel',

    job_bots_title: 'כותרת',
    job_bots_description: 'תיאור',
    job_bots_actionTypes: 'גורם הפעלה',
    job_bots_html: 'HTML Script',
    job_bots_designUrl: 'Desgin URL',
    job_bots_dataUrl: 'Data URL',
    job_bots_status: 'סטטוס',

    'jobList_header_order': 'סדר',
    'jobList_header_title': 'כותרת משרה',
    'jobList_header_active': 'משרה פעילה',
    'jobList_header_createdAt': 'נוצרה בתאריך',
    'jobList_header_applicants': 'מועמדים',
    'jobList_header_views': 'צפיות',
    'jobList_header_shares': 'שיתופים',
    'jobList_header_actions': 'פעולות',
    'jobList_header_innerRecruiter': 'מגייס/ת',
    'jobList_header_innerRecruiterManager': 'מנהל גיוס',
    'jobList_header_section': 'מחלקה/תחום',
    'jobList_header_internalId': 'מזהה פנימי',

    'jobList_botData': 'נתוני בוט',
    'jobList_sendWaLabel': 'שלחו וואטסאפ',
    'jobList_sendWaTitle': 'הפיצו הודעה בוואטסאפ',
    'jobList_sendLabel': 'שלח',
    'jobList_promoteLabel': 'תנו PUSH',
    'jobList_pushTitle': 'תן PUSH למשרה',
    'jobList_pushConfirmationBold': 'האם אתה בטוח שאתה רוצה לבקש PUSH למשרה "{name}"?',
    'jobList_pushConfirmationReg': '{customerName} תדחף בשבילך את המשרה במדיה הדיגיטלית',
    'jobList_pushLabel': 'PUSH!',
    jobList_sendNotification: 'שליחת התראה',
    jobList_copyJob: 'העתקת לינק למשרה',
    jobList_copyAllJob: 'העתקת לינק לכל המשרות',
    jobList_showQr: 'הצגת QR CODE',
    jobList_qrcode_title: 'QR Code למשרת {jobTitle}',
    jobList_qrcode_all_jobs_title: 'QR Code לדף כל המשרות',
    jobList_recruitmentMessage: 'הודעה מצוות הגיוס',
    jobList_recruitmentMessageDetails: 'כאן המקום לכתוב הודעה במלל חופשי, שתגיע לכלל העובדים',

    jobList_waRecipient: 'למי תרצו להפיץ את ההודעה?',
    jobList_waRecipient_user: 'אליי לבדיקה',
    jobList_waRecipient_referrers: 'כל העובדים',

    jobsFilter_filter_title: 'סינון משרות',
    'jobsFilter_job': 'כותרת משרה',
    jobsFilter_title: 'כותרת משרה',
    'jobsFilter_internalId': 'מזהה משרה פנימי',
    'jobsFilter_innerRecruiter': 'מגייס/ת',
    'jobsFilter_innerRecruiterManager': 'מנהל/ת גיוס',
    jobsFilter_section: 'מחלקה/תחום',
    jobsFilter_locationTitle: 'מיקום',
    jobsFilter_freeSearch: 'חיפוש חופשי',

    'jobList_actions_duplicate': 'שכפל משרה',

    'jobs_fullInfo_title': 'לפרטי המשרה המלאים',
    'job_inactive_title': 'סליחה, משרה זו איננה פעילה יותר',

    'referrer_internalId': 'מס\' עובד',
    'referrer_name': 'שם מלא',
    'referrer_phone': 'טלפון',
    'referrer_identifier': 'מזהה עובד',
    'referrer_email': 'אימייל',
    'referrer_section': 'מחלקה',
    'referrer_birthday': 'יום הולדת',
    'referrer_comment': 'שדה 1',
    'referrer_comment2': 'שדה 2',
    'referrer_comment3': 'שדה 3',
    'referrer_applicants': 'מועמדים',
    'referrer_views': 'צפיות',
    'referrer_shares': 'שיתופים',
    'referrer_dateRangeApplicants': 'מועמדים',
    'referrer_dateRangeViews': 'צפיות',
    'referrer_dateRangeShares': 'שיתופים',
    'referrer_gdpr': 'מסכים לתנאים',
    'referrer_createdAt': 'תאריך יצירה',
    'referrer_lastActive': 'פעיל לאחרונה',
    'referrer_activityDate': 'תאריך פעילות',

    'create_new_referrer': 'יצר עובד חדש',
    'error_referrerAlreadyExists': 'עובד עם המזהה הנוכחי כבר קיים במערכת',

    //menu
    'menu_menuItem_companies': 'חברות',
    'menu_menuItem_referrers': 'עובדים ממליצים',
    'menu_menuItem_details': 'פרטי חברה',
    'menu_menuItem_newCompany': 'הוסף חברה',
    menu_menuItem_apiKeys: 'מפתחות Api',
    menu_menuItem_general: 'כללי',
    menu_menuItem_notifications: 'התראות לעובדים',
    menu_menuItem_notifHistory: 'היסטורית התראות',
    menu_menuItem_cmpSettings: 'הגדרות חברה',
    menu_menuItem_cmpAdminSettings: 'ניהול חברה',
    menu_menuItem_cmpIntegrations: 'אינטגרציות',
    'menu_menuItem_users': 'ניהול משתמשים',
    'menu_menuItem_userList': 'רשימת משתמשים',
    'menu_menuItem_newJob': 'משרה חדשה',
    'menu_menuItem_applicants': 'מועמדים',
    'menu_menuItem_dashboard': 'דשבורד',
    'menu_menuItem_companyJobs': 'משרות',
    'menu_menuItem_newUser': 'משתמש חדש',
    'menu_menuItem_allUsers': 'כל המשתמשים',
    'menu_menuItem_settings': 'הגדרות',
    'menu_menuItem_logout': 'התנתק',
    'companyNotFound': 'חברה לא נמצאה.',

    'navigation_addNewJob': 'הוסף משרה חדשה',

    'shareJobForm_title': 'הכנס/י את פרטייך כדי להעביר מועמדים דרכך',
    'shareJobForm_identifier': 'טלפון',
    'shareJobForm_identifier_phone': 'טלפון',
    'shareJobForm_identifier_email': 'אימייל חברה',
    'shareJobForm_name': 'שם מלא',
    'shareJobForm_gdpr': 'אני מסכימ/ה לקבל הודעות ומסרים בהתאם {terms}',
    'shareJobForm_gdpr-link': 'לתקנון',
    'shareJobForm_submitLabel': 'צור לי לינק ייחודי',

    'generatedLink_name': 'היי {name}, ',
    'generatedLink_linkMessage': 'בחר/י כיצד לשתף את המשרה',
    'generatedLink_linkMessage_jobs': 'שתפ/י את המשרות שלנו',
    generatedLink_greeting_back: 'כיף שחזרת! ',

    'applicantsFilter_submit': 'סנן',
    'applicantsFilter_referrerIdentifier': 'מזהה - עובד ממליץ',
    'applicantsFilter_date': 'תאריך',
    'applicantsFilter_companyId': 'חברה',    
    'applicantsFilter_jobTitle': 'כותרת משרה',
    'applicantsFilter_status': 'סטטוס',
    
    'applicant_status_new': 'חדש',
    'applicant_status_irrelevant': 'לא רלוונטי',
    'applicant_status_recruited': 'גויס',
    'applicant_status_inProgress': 'בתהליך',
    applicant_status_interviewScheduled: 'זומן לראיון',
    applicant_status_interviewed: 'הגיע לראיון',
    
    applicant_application_name: 'שם מלא',
    applicant_application_phone: 'טלפון',
    applicant_application_email: 'אימייל',
    applicant_application_profileURL: 'קישור לפרופיל LinkedIn',
    applicant_application_cv: 'קו"ח',
    applicant_application_aboutMe: 'קצת עלי',
    applicant_application_gdpr: 'מסכים לתנאים',

    'applicant_name': 'שם מלא',
    'applicant_referrerId': 'עובד ממליץ',
    'applicant_companyId': 'חברה',
    'applicant_jobId': 'מסֿֿ\' משרה',
    'applicant_status': 'סטטוס',
    'applicant_email': 'אימייל',
    'applicant_phone': 'טלפון',
    'applicant_createdAt': 'תאריך',
    'applicant_cv': 'קו"ח',
    'applicant_cvFile': 'קובץ קו"ח',
    'applicant_aboutMe': 'מידע על המועמד',
    'applicant_profileURL': 'קישור לפרופיל LinkedIn',
    'applicant_gdpr': 'מסכים לתנאים',
    applicant_botIntegrations: 'מידע מהבוט',
    'applicant_recruiterSummary': 'סיכום צוות גיוס',
    'applicant_aiSummary': 'סיכום AI',

    applicant_redacted_warning: 'חלק מהמידע על המועמד הוסר. ניתן לשנות הגדרה זו ב"פרטי החברה"',

    'applicant_save': 'שמור פרטים',

    'applicantList_notFound': 'אין עדיין מועמדים :(',
    'applicantList_cvFile': 'קובץ',

    'createApplicationForm_showMore': 'אופציות נוספות',
    'createApplicationForm_submit': 'הגשת מועמדות',
    'createApplicationForm_applied': 'הגשת מועמדותך נקלטה במערכת!',
    'createApplicationForm_goodLuck': 'בהצלחה!',
    'createApplicationForm_moreJobsTitle': 'הצצת במשרות הנוספות שלנו?',
    'createApplicationForm_moreJobsSubtitle': 'אולי יש משרה שמתאימה לחברים שלך',
    'createApplicationForm_cv': 'העלאת קורות חיים (PDF/docx)',
    'createApplicationForm_gdpr': 'אני מסכימ/ה לקבל הודעות ומסרים בהתאם {terms}',
    'createApplicationForm_gdpr-link': 'לתקנון',

    'user_role_cmpAdmin': 'מנהל חברה',
    'user_role_admin': 'מנהל מערכת',

    'users_usersNotFound': 'לא יצרת עדיין משתמשים',
    userList_header_mfaStatus: 'MFA',
    'userList_header_displayName': 'שם',
    'userList_header_username': 'אימייל',
    'userList_header_companyId': 'חברה',
    'userList_header_role': 'הרשאה',
    'userList_header_updatedAt': 'ת. עדכון',
    'userList_header_phone': 'טלפון',
    'userList_header_active': 'סטטוס',
    'userList_deleteTItle': 'מחיקת משתמש',
    'userList_deleteConfirmationBold': 'האם אתה בטוח שאתה רוצה למחוק את {name}?',
    'userList_deleteConfirmationReg': 'משתמש זה לא יוכל להתחבר יותר למערכת',

    'create_user_submit': 'צור משתמש',
    'edit_user_submit': 'עדכן משתמש',
    'createEditUser_label_displayName': 'שם',
    'createEditUser_label_username': 'אימייל',
    'createEditUser_label_companyId': 'חברה',
    'createEditUser_label_password': 'סיסמה',
    'createEditUser_label_phone': 'טלפון',
    'createEditUser_label_mfaStatus': 'דרוש MFA',
    'createEditUser_label_role': 'הרשאה',
    'createEditUser_label_active': 'פעיל',

    apiKeysList_header_name: 'שם',
    apiKeysList_header_enabled: 'פעיל',
    apiKeysList_header_companyId: 'שם חברה',
    apiKeysList_deleteTitle: 'מחיקת מפתח Api',
    apiKeysList_deleteConfirmationBold: 'האם אתה בטוח שאתה רוצה למחוק את {name}?',
    apiKeysList_deleteConfirmationReg: 'פעולה זו לא ניתנת לשחזור',

    create_apiKey_submit: 'יצירת מפתח Api',
    edit_apiKey_submit: 'עדכון מפתח Api',
    createEditApiKey_label_name: 'שם',
    createEditApiKey_label_email: 'Email',
    createEditApiKey_label_enabled: 'פעיל',
    createEditApiKey_label_companyId: 'שם חברה',
    createEditApiKey_label_key: 'מפתח גישה',

    'actions_edit': 'עריכה',
    'actions_cancel': 'ביטול',
    'actions_create': 'יצירה',

    'fileUpload_fileSelected': 'קובץ נבחר',
    'imageUpload_replace': 'גרור תמונה אחרת (או לחץ) כדי להחליף תמונה',
    'imageUpload_prefered': 'גודל מומלץ - {preferedDimentions} (עד {maxSize})',

    'form_requiredField': 'שדה זה הינו שדה חובה',
    'form_unsavedChanges': 'ישנם שינויים שלא שמרת. האם ברצונך לעזוב את הדף?',

    'contact_title': 'צור קשר והתראות',
    'incentives_title': 'תמריצים',
    referrer_stats_title: 'תיבת ההישגים של העובד',

    settings_title: 'הטבות החודש ותנאי שימוש',
    settings_companyId: 'חברה',
    settings_terms: 'תקנון',
    settings_reset_title: 'איפוס חברה',
    settings_reset_subtitle: 'פעולה זו תמחק את כל המשרות, העובדים, המועמדים, ההתראות והקבצים המשוייכים להם',

    'referrer_explanation_title': 'הסבר לעובד ממליץ',
    'jobs_link_sub_cmp': 'לדף משרות',
    'job_location_and_map_title': 'מיקום המשרה',

    'company_location_and_map_title': 'מיקום החברה',
    'general_details_title': 'פרטים כלליים',

    'validation_errors_present': 'קיימות שגיאות בטופס',
    'validation_required': 'שדה חובה',
    'validation_minLength': 'מינימום {minLength} תוים',
    'validation_exactLength': 'אורך צריך להיות {length} תוים',
    'validation_email': 'אימייל אינו תקין',
    'validation_identification_unavailable': 'המזהה תפוס',
    'validation_identification_available': 'המזהה פנוי',
    'validation_cv': 'יש להעלות קובץ PDF/docx עד 5MB',
    'validation_invalid_media_file': 'גודל/סוג קובץ אינו חוקי',
    validation_insecure_pass: 'סיסמה חלשה מדי',
    validation_min: 'מינימום {min}',
    validation_invalidRegex: 'RegExp לא חוקי',
    validation_invalidPattern: 'תבנית לא חוקית',

    'operation_success': 'הפעולה הושלמה בהצלחה',
    'operation_failure': 'הפעולה נכשלה',

    'dashboard_totalJobs': 'משרות במערכת',
    'dashboard_activeJobs': 'משרות פעילות',
    'dashboard_inactiveJobs': 'משרות לא פעילות',
    'dashboard_totalCompanies': 'חברות',
    'dashboard_referrers': 'עובדים שנרשמו',
    'dashboard_activeEmployees': 'אחוז עובדים שנרשמו',
    'dashboard_shares': 'שיתופים',

    'dashboard_column_title': 'כותרת המשרה',
    'dashboard_column_views': 'צפיות',
    'dashboard_column_applicants': 'מועמדים',
    'dashboard_column_shares': 'שיתופים',
    'dashboard_column_name': 'שם',
    'dashboard_column_email': 'אימייל',
    'dashboard_column_phone': 'טלפון',

    'dashboard_title_totalViews': 'צפיות',
    'dashboard_title_totalApplicants': 'מועמדים',

    'admin_exportCSV': 'יצא ל-CSV',
    
    'referrerWorth_title': 'למה זה כדאי לי?',
    'return_to_alljobs': 'למשרות נוספות',
    'our_jobs': 'למשרות שלנו >',

    // draftjs
    // Generic
    'generic.add': 'הוסף',
    'generic.cancel': 'בטל',
    // BlockType
    'components.controls.blocktype.h3': 'כותרת',
    'components.controls.blocktype.normal': 'רגיל',
    // Emoji
    'components.controls.emoji.emoji': 'Emoji',
    // History
    'components.controls.history.history': 'הסטוריה',
    'components.controls.history.undo': 'בטל',
    'components.controls.history.redo': 'חזור',
    // TextAlign
    'components.controls.textalign.textalign': 'יישור טקסט',
    'components.controls.textalign.left': 'ישר לשמאל',
    'components.controls.textalign.center': 'מרכז',
    'components.controls.textalign.right': 'יישור לימין',
    'components.controls.textalign.justify': 'יישור לשני הצדדים',
    // Inline
    'components.controls.inline.bold': 'מודגש',
    'components.controls.inline.italic': 'מוטה',
    'components.controls.inline.underline': 'קו תחתון',
    'components.controls.inline.strikethrough': 'קו חוצה',
    'components.controls.inline.monospace': 'רווח אחיד',
    'components.controls.inline.superscript': 'טקסט עליון',
    'components.controls.inline.subscript': 'טקסט תחתון',

    //react-dates
    'date_startDate': 'מתאריך',
    'date_endDate': 'עד תאריך',
    'date_startTime': 'משעה',
    'date_endTime': 'עד שעה',

    'shareableLinks_fb': 'פייסבוק',
    'shareableLinks_ln': 'לינקדאין',
    'shareableLinks_wa': 'וואטסאפ',
    'shareableLinks_url': 'העתק קישור',
    'shareableLinks_coppied': 'הועתק',
    'shareableLinks_manualCopy': 'CTRL+C כדי להעתיק',

    'unsubscribe_message': 'האם אתה בטוח שברצונך להפסיק לקבל הודעות?',
    'unsubscribe': 'הפסק קבלת הודעות',

    'footer_terms': 'תקנון',
    'footer_rights': 'כל הזכויות שמורות ל-PRO-MAN',
    'footer_developed': 'פיתוח: SCOTTY',
    
    'cropModal_title': 'חתוך תמונה',
    'cropModal_leaveUncropped': 'אל תחתוך',
    'cropModal_crop': 'חתוך את התמונה',
    'cropModal_explain': 'גרור את התמונה למיקום הרצוי. השתמש בגלגלת על מנת לעשות זום',
    'cropModal_notSupported': 'חיתוך וידאו אינו נתמך',
    'cropModal_previewWeb': 'כך תראה התמונה במחשב:',
    'cropModal_previewMobile': 'כך תראה התמונה בטלפון:',
    'cropModal_previews_title': 'תצוגה מקדימה',

    'sharesList_header_referrer': 'עובד ממליץ',
    'sharesList_header_date': 'תאריך',
    'sharesList_header_share': 'סוג שיתוף',
    'sharesList_header_job': 'משרה ששותפה',
    'sharesList_share_wa': 'ווטסאפ',
    'sharesList_share_fb': 'פייסבוק',
    'sharesList_share_ln': 'לינקדאין',
    'sharesList_share_url': 'העתק קישור',
    'sharesList_jobListShare': '<דף משרות>',

    'importCsv_title': 'יבא עובדים מקובץ csv',
    'importCsv_fileUploadField': 'גרור לכאן קובץ Excel/CSV של העובדים',
    'importCsv_fileUploadExample': 'קובץ csv לדוגמה',
    'importCsv_advanced_toggle': 'הגדרות מתקדמות',
    'importCsv_status_success': 'יבוא מתאריך {date} הושלם בהצלחה.',
    'importCsv_status_inProgress': 'יבוא מתאריך {date} נמצא בתהליך',
    'importCsv_status_failure': 'יבוא מתאריך {date} נכשל.',
    'importCsv_importInProgress': 'יבוא העובדים החל. תהליך זה יכול לקחת כמה דקות...',
    'importCsv_failureReason_invalidIdentifier': 'מזהה עובד אינו חוקי בשורה: {line}. (אימייל/טלפון regex)',
    'importCsv_failureReason_invalidEmail': 'שדה "אימייל" אינו חוקי בשורה: {line}.',
    'importCsv_failureReason_invalidGdpr': 'שדה "מסכים לתנאים" אינו חוקי בשורה: {line}. (יש להזין true/false/TRUE/FALSE)',
    'importCsv_failureReason_invalidBirthday': 'שדה "יום הולדת" אינו חוקי בשורה: {line}. (יש להזין בפורמט 27/10/2001)',
    'importCsv_failureReason_contactAdmin': 'אנא פנה למנהל המערכת.',
    'importCsv_overrideWarning': 'שימו לב: ייבוא קובץ עובדים מוחק את טבלת העובדים הקיימת במערכת.',
    'importCsv_ignoreFirstLine': 'התעלם משורה ראשונה',

    'referrersFilter_findReferrer': 'מצא עובד',
    'referrersFilter_segmentReferrers': 'סנן עובדים ליצוא',
    'referrersFilter_fieldChooser_title': 'שדה תאריך',

    'template_companyName': 'שם חברה',
    'template_jobInternalId': 'מזהה משרה',
    'template_jobTitle': 'כותרת המשרה',
    'template_referrerPhone': 'טלפון עובד ממליץ',
    'template_referrerName': 'שם עובד ממליץ',
    'template_applicantName': 'שם מועמד',

    'locale_en': 'אנגלית',
    'locale_he': 'עברית',

    'not_supported': 'פאנל הניהול איננו זמין באינטרנט אקספלורר. יש להשתמש בדפדפן מתקדם יותר',

    notifications_enabled: 'התראות מופעלות',
    notifications_disabled: 'התראות מושבתות',
    notifications_whatsapp_status_title: 'התראות וואטסאפ לעובד על מועמדים חדשים',
    notifications_whatsapp_new_emp_title: 'התראות ווטסאפ לעובדים חדשים',
    notifications_status_title: 'סטטוס התראות PUSH גלובלי',
    notifications_disabled_warning: 'העובדים שלכם לא מקבלים התראות על משרות חדשות ומועמדים שנקלטו דרכם! מעוניינים גם? - צרו איתנו קשר',
    notifications_quota_warning: 'נגמרה לכם חבילת ההתראות.\n רוצים לחדש? צרו איתנו קשר',

    notifications_email_templates_title: 'התראות לאימייל',
    notif_template_newApplicantEmail_title: 'התראה במייל לעובד על מועמד חדש',
    'templates.newApplicantEmail.config.imageHeader': 'תמונת הבאנר',

    notif_amountLeft: 'כמות התראות שנותרה',
    notif_monthlyBank: 'חבילה חודשית',
    notif_monthlyBank_explain: 'כל חודש כמות ההתראות שלכם עולה חזרה לסכום החבילה החודשית שלכם',
    notifications_templates_title: 'תבניות התראת push לעובדים',
    notif_template_newJob_title: 'תבנית למשרה חדשה',
    notif_template_newApplicant_title: 'תבנית למועמד חדש',
    notif_template_title: 'תבנית לכותרת ההתראה',
    notif_template_body: 'תבנית לגוף ההתראה',
    notif_amountWaLeft: 'כמות הודעות וואטסאפ שנותרה',

    create_notif_title: 'כותרת התראה',
    create_notif_body: 'גוף התראה',
    create_notif_img: 'תמונת ההתראה',
    create_notif_approximate: `ההתראה תשלח {number, plural,
        =0 {למספר עובדים לא ידוע}
        one {לעובד אחד}
        other {לכ-# עובדים}
    }`,
    create_notif_disabled: 'שליחת התראות מנוטרלת. יש לאפשר בדף התראות לעובדים',

    send_job_notification: 'שליחת התראה למשרה',
    notif_request_register_title: 'קבלו התראות כשחבר שלכם נרשם דרכיכם וכשעולות משרות חדשות!',
    notif_revoke: 'הסר התראות',
    notif_revoked: 'התראות בוטלו',
    notif_cantSendNotification: 'אין לכם חבילת התראות\nמעוניינים גם? צרו איתנו קשר',

    notifHistory_header_type: 'סוג',
    notifHistory_header_title: 'כותרת',
    notifHistory_header_createdAt: 'תאריך יצירה',
    notifHistory_header_received: 'כמות שליחות',
    notifHistory_header_opened: 'כמות פתיחות',

    notif_type_newJob: 'משרה חדשה',
    notif_type_newApplicant: 'מועמד חדש',
    
    referrersList_disableRevokeNotifTitle: 'ביטול התראות לעובד',
    referrersList_disableRevokeNotifBold: 'האם לבטל התראות לעובד {name}?',
    referrersList_disableRevokeNotifReg: 'פעולה זו הינה בלתי הפיכה ועובד זה לא יוכל לקבל התראות בהמשך באמצעות הלינק שיצר',
    referrersList_revokeLabel: 'ביטול התראות',

    previewRatio_fb: 'Facebook',
    previewRatio_wa: 'Whatsapp',

    image_preview_clear_cache: 'לאחר שינוי אנו ממליצים לאפס את הקאש של Whatsapp כדי לוודא שהשינוי ישתקף. להוראות איפוס קאש <a class="link" href="https://linkatch.com/SupportCenter/Clear-Cache.html" target="_blank">לחצו כאן</a>',

    confirmation_modal_insert_text: 'על מנת לבצע את הפעולה, יש להזין "{text}" בתיבת הטקסט',
    settings_reset_confirm_title: 'איפוס חברה',
    settings_reset_confirm_contentBold: 'האם לאפס את החברה {name}?',
    settings_reset_confirm_contentReg: 'פעולה זו תמחק את כל המשרות, העובדים, המועמדים, ההתראות והקבצים המשוייכים להם. לא יהיה ניתן לשחזר את התוכן לאחר ביצוע הפעולה',

    company_admin_settings_title: 'הגדרות ניהול חברה',
    company_admin_settings_limits_title: 'הגבלות חשבון',
    company_admin_settings_activeJobsLimit: 'הגבלת משרות פעילות (0 = ללא מגבלה)',
    company_admin_settings_jobs_ai_title: 'משוב AI על מועמדים',
    applicants_ai_enabled: 'AI מופעל',
    applicants_ai_disabled: 'AI מושבת',

    company_settings_title: 'הגדרות חברה',
    company_settings_list_values_department_title: 'רשימת ערכים למחלקה',
    company_settings_list_values_location_title: 'רשימת ערכים למיקומים',

    company_settings_list_values_selector_label: 'בחירת רשימת ערכים',
    company_settings_list_values_selector_department: 'רשימת ערכים למחלקה',
    company_settings_list_values_selector_location: 'רשימת ערכים למיקומים',

    company_settings_identifier_section_title: 'מזהה עובדים',
    company_settings_referrerConfig_nameLabel: 'תווית לשדה "שם"',
    company_settings_identifierType: 'סוג מזהה',
    company_settings_referrerConfig_identifierLabel: 'תווית לשדה "מזהה"',
    company_settings_referrerConfig_identifierRegex: 'אימות Regex לשדה "מזהה"',
    company_settings_referrerConfig_identifierRegexErrorMessage: 'שגיאת אימות Regex לשדה "מזהה"',
    
    identifierType_email: 'אימייל',
    identifierType_phone: 'טלפון',

    toaster_activeJobsLimitReached: 'לא ניתן לייצר יותר משרות פעילות במנוי זה. יש לבטל משרה פעילה אחרת או ליצור קשר עם התמיכה להעלאת המגבלה',
    toaster_contact_support: 'יש לפנות לתמיכה עם מזהה השגיאה:',

    form_values_list_new_placeholder: 'ערך חדש',
    form_values_list_new_button: 'הוספה',
    form_values_list_duplicate_error: 'ערך זה כבר נמצא ברשימה',

    referrer_stats_modal_title: 'תיבת ההישגים שלך',
    referrer_stats_modal_welcome: 'שלום {name},',
    referrer_stats_my_settings: "ההגדרות שלי",
    referrer_stats_modal_default_description: 'הנה סטטיסטיקות השיתופים שלך, ניתן לשנות את התקופה באמצעות הסטטיסטיקות פה למטה, או להתנתק באמצעות לחיצה על הכפתור בתחתית החלון',
    referrer_stats_modal_period: 'תקופה',
    referrer_stats_modal_period_allTime: 'כל הזמן',
    referrer_stats_modal_period_lastWeek: 'שבוע אחרון',
    referrer_stats_modal_period_lastMonth: 'חודש אחרון',
    referrer_stats_modal_stat_applicants: 'מועמדים',
    referrer_stats_modal_stat_shares: 'שיתופים',
    referrer_stats_modal_stat_views: 'צפיות',
    referrer_stats_modal_logout: 'התנתקות',
    referrer_stats_modal_trigger_desc: 'ההישגים שלי',

    integration_kind_label: 'סוג איטנגרציה',
    'integration_kind_option_importJobs:comeet': 'קומיט - משיכת משרות',
    'integration_kind_option_importJobs:adam': 'אדם - משיכת משרות',
    integrattion_modal_create_title: 'יצירת איטגרציה: {kind}',
    integrattion_modal_edit_title: 'עריכת איטגרציה: {kind}',
    'integration_importJobs:comeet_config.employerUID': 'מזהה חברה בקומיט',
    'integration_importJobs:adam_config.token': 'אסימון חברה באדם',
    'integration_importJobs:adam_config.refferalFilter': 'קוד סינון',
    integration_deleteTitle: 'מחיקת איטגרציה',
    integration_deleteConfirmationBold: 'האם למחוק את האינטגרציה: "{kind}"?',
    integration_deleteConfirmationReg: 'האינטגרציה תוסר מהמערכת ולא תפעל יותר',
}
