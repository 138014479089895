import React, {Component} from 'react';
import Modal from 'components/Modal';
import Title from 'components/Title';
import Flex from 'components/Flex';
import Button from 'components/Button';
import classnames from 'classnames';
import {TextInput} from 'components/Form';
import Messages from 'i18n/defaultMessages';
import { FormattedMessage } from 'react-intl';
import Logo from 'images/logo.png';

export default class ConfirmationModal extends Component {
    state = {dangerInput: ''}

    renderContent = () => {
        const {children, contentBold, contentReg} = this.props;
        if (children) {
            return (
                <div className="confirmation-modal__content">
                    {children}
                </div>
            );
        }
        return (
            <div className="confirmation-modal__content">
                <Flex>
                    <Title>{contentBold}</Title>
                    <Title regular>{contentReg}</Title>
                </Flex>
            </div>
        );
    }

    renderDangerInput = () => {
        const {dangerText} = this.props;
        if (!dangerText) {
            return null
        }
        return (
            <div className="confirmation-modal__content">
                <TextInput
                    label={<FormattedMessage {...Messages[`confirmation_modal_insert_text`]} values={{text: dangerText}} />}
                    onChange={(e, val) => this.setState({dangerInput: val})}
                />
            </div>
        )
    }

    render = () => {
        const {children, title, contentBold, contentReg, className, danger, dangerText, showLogo, 
                primaryLabel, onPrimaryClick, secondaryLabel, onSecondaryClick, primaryDisabled,
                ...rest
        } = this.props;
        const content = this.renderContent();
        const isPrimaryDisabled = primaryDisabled || (dangerText && this.state.dangerInput.trim() !== dangerText);
        return (
            <Modal
                {...rest}
                className={classnames('confirmation-modal', className, {'confirmation-modal--danger': danger})}
                noPadding
            >
                {showLogo && <div className="confirmation-modal__logoBox">
                    <img
                        src={Logo}
                        className="confirmation-modal__logo"
                        alt="logo"
                    />
                </div>}
                <div className="confirmation-modal__title">
                    {title && <Title>{title}</Title>}
                </div>
                {content}
                {this.renderDangerInput()}
                <Flex justify="end" horizontal component="footer" className="confirmation-modal__footer">
                    {secondaryLabel && <Button danger={danger} text={secondaryLabel} onClick={onSecondaryClick} />}
                    {primaryLabel && <Button disabled={isPrimaryDisabled} primary danger={danger} text={primaryLabel} onClick={onPrimaryClick} />}
                </Flex>
            </Modal>
        );
    }
}
